define("discourse/plugins/discourse-akismet/discourse/templates/components/reviewable-akismet-api-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="reviewable-score-reason">
    {{i18n "admin.akismet_api_error"}}
    {{this.external_error.error}}
    ({{this.external_error.code}})
    {{this.external_error.msg}}
  </div>
  */
  {
    "id": "N1X5LgS9",
    "block": "[[[10,0],[14,0,\"reviewable-score-reason\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"admin.akismet_api_error\"],null]],[1,\"\\n  \"],[1,[30,0,[\"external_error\",\"error\"]]],[1,\"\\n  (\"],[1,[30,0,[\"external_error\",\"code\"]]],[1,\")\\n  \"],[1,[30,0,[\"external_error\",\"msg\"]]],[1,\"\\n\"],[13]],[],false,[\"div\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-akismet/discourse/templates/components/reviewable-akismet-api-error.hbs",
    "isStrictMode": false
  });
});