define("discourse/plugins/discourse-presence/discourse/services/composer-presence-manager", ["exports", "@ember/runloop", "@ember/service", "discourse-common/config/environment"], function (_exports, _runloop, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KEEP_ALIVE = 10 * 1000; // 10 seconds

  class ComposerPresenceManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "presence", [_service.service]))();
    #presence = (() => (dt7948.i(this, "presence"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    notifyState(name) {
      let replying = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      let keepAlive = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : KEEP_ALIVE;
      if (!replying) {
        this.leave();
        return;
      }
      const canHideProfile = this.siteSettings.allow_users_to_hide_profile;
      const isHidingPresence = this.currentUser.user_option.hide_presence;
      if (canHideProfile && isHidingPresence) {
        return;
      }
      if (this._name !== name) {
        this.leave();
        this._name = name;
        this._channel = this.presence.getChannel(name);
        this._channel.enter();
        if (!(0, _environment.isTesting)()) {
          this._autoLeaveTimer = (0, _runloop.debounce)(this, this.leave, keepAlive);
        }
      }
    }
    leave() {
      if (this._autoLeaveTimer) {
        (0, _runloop.cancel)(this._autoLeaveTimer);
        this._autoLeaveTimer = null;
      }
      this._channel?.leave();
      this._channel = null;
      this._name = null;
    }
  }
  _exports.default = ComposerPresenceManager;
});