define("discourse/plugins/discourse-assign/discourse/templates/connectors/user-messages-nav/assigned-messages", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="userPrivateMessages.assigned" @model={{this.model}}>
    {{d-icon "user-plus" class="glyph"}}
    {{i18n "discourse_assign.assigned"}}
  </LinkTo>
  */
  {
    "id": "bZDswpMC",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@model\"],[\"userPrivateMessages.assigned\",[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"user-plus\"],[[\"class\"],[\"glyph\"]]]],[1,\"\\n  \"],[1,[28,[35,2],[\"discourse_assign.assigned\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-assign/discourse/templates/connectors/user-messages-nav/assigned-messages.hbs",
    "isStrictMode": false
  });
});