
    import { template as compiler } from "discourse-common/lib/raw-handlebars";
    import { addRawTemplate } from "discourse-common/lib/raw-templates";

    let template = compiler({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <a href=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.url",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":2,"column":11},"end":{"line":2,"column":24}}}))
    + "\" class=\"topic-excerpt\">\n    "
    + alias2((lookupProperty(helpers,"dir-span")||(depth0 && lookupProperty(depth0,"dir-span"))||container.hooks.helperMissing).call(alias1,"topic.escapedExcerpt",{"name":"dir-span","hash":{"htmlSafe":"true"},"hashTypes":{"htmlSafe":"StringLiteral"},"hashContexts":{"htmlSafe":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":53}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.excerptTruncated",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(2, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "  </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span class=\"topic-excerpt-more\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"read_more",{"name":"i18n","hash":{},"hashTypes":{},"hashContexts":{},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":5,"column":39},"end":{"line":5,"column":59}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),"topic.hasExcerpt",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(1, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "");
},"useData":true});

    addRawTemplate("list/topic-excerpt", template, {
      core: true,
      pluginName: undefined,
      hasModernReplacement: false,
    });

    export default template;
  