import { template as template_061c4f1ed9454febafbe06c8ffb631d2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_061c4f1ed9454febafbe06c8ffb631d2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
