define("discourse/plugins/discourse-user-notes/discourse-user-notes/templates/connectors/admin-user-controls-after/add-user-notes-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ShowUserNotes
    @show={{action "showUserNotes"}}
    @count={{this.userNotesCount}}
  />
  */
  {
    "id": "3gWV5hB6",
    "block": "[[[8,[39,0],null,[[\"@show\",\"@count\"],[[28,[37,1],[[30,0],\"showUserNotes\"],null],[30,0,[\"userNotesCount\"]]]],null]],[],false,[\"show-user-notes\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-user-notes/discourse-user-notes/templates/connectors/admin-user-controls-after/add-user-notes-button.hbs",
    "isStrictMode": false
  });
});