define("discourse/plugins/discourse-assign/discourse/templates/connectors/group-reports-nav-item/assigned-topic-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <GroupAssignedMenuItem @group={{this.group}} />
  */
  {
    "id": "K6gPJHoW",
    "block": "[[[8,[39,0],null,[[\"@group\"],[[30,0,[\"group\"]]]],null]],[],false,[\"group-assigned-menu-item\"]]",
    "moduleName": "discourse/plugins/discourse-assign/discourse/templates/connectors/group-reports-nav-item/assigned-topic-list.hbs",
    "isStrictMode": false
  });
});