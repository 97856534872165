define("discourse/plugins/discourse-assign/discourse/templates/connectors/user-preferences-notifications/remind-assigns-frequency", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <RemindAssignsFrequency @user={{this.model}} />
  */
  {
    "id": "15tbayEQ",
    "block": "[[[8,[39,0],null,[[\"@user\"],[[30,0,[\"model\"]]]],null]],[],false,[\"remind-assigns-frequency\"]]",
    "moduleName": "discourse/plugins/discourse-assign/discourse/templates/connectors/user-preferences-notifications/remind-assigns-frequency.hbs",
    "isStrictMode": false
  });
});