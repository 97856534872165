define("discourse/plugins/discourse-akismet/discourse-akismet/templates/connectors/flag-modal-bottom/akismet-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.post.akismet_state}}
    <div class="consent_banner alert alert-info">
      <span>{{i18n (concat "akismet.post_state." this.post.akismet_state)}}</span>
    </div>
  {{/if}}
  */
  {
    "id": "/zjr+ZdY",
    "block": "[[[41,[30,0,[\"post\",\"akismet_state\"]],[[[1,\"  \"],[10,0],[14,0,\"consent_banner alert alert-info\"],[12],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,3],[[28,[37,4],[\"akismet.post_state.\",[30,0,[\"post\",\"akismet_state\"]]],null]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"span\",\"i18n\",\"concat\"]]",
    "moduleName": "discourse/plugins/discourse-akismet/discourse-akismet/templates/connectors/flag-modal-bottom/akismet-status.hbs",
    "isStrictMode": false
  });
});