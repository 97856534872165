define("discourse/plugins/discourse-user-notes/discourse/templates/connectors/admin-dashboard-moderation-bottom/recent-user-notes-report-table", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.user_notes_enabled}}
    <AdminReport @dataSourceName="user_notes" @filters={{this.filters}} />
  {{/if}}
  */
  {
    "id": "pKwX1kcL",
    "block": "[[[41,[30,0,[\"siteSettings\",\"user_notes_enabled\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@dataSourceName\",\"@filters\"],[\"user_notes\",[30,0,[\"filters\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"admin-report\"]]",
    "moduleName": "discourse/plugins/discourse-user-notes/discourse/templates/connectors/admin-dashboard-moderation-bottom/recent-user-notes-report-table.hbs",
    "isStrictMode": false
  });
});