define("discourse/plugins/chat/discourse/services/chat-notification-manager", ["exports", "@ember/service", "discourse/lib/desktop-notifications", "discourse-common/config/environment", "discourse-common/utils/decorators"], function (_exports, _service, _desktopNotifications, _environment, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNotificationManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    willDestroy() {
      super.willDestroy(...arguments);
      if (!this.#shouldRun) {
        return;
      }
      this.messageBus.unsubscribe(this.messageBusChannel, this.onMessage);
    }
    start() {
      if (!this.#shouldRun) {
        return;
      }
      this.messageBus.subscribe(this.messageBusChannel, this.onMessage);
    }
    get messageBusChannel() {
      return `/chat${(0, _desktopNotifications.alertChannel)(this.currentUser)}`;
    }
    async onMessage(data) {
      // if the user is currently focused on this tab and channel,
      // we don't want to show a desktop notification
      if (this.session.hasFocus && data.channel_id === this.chat.activeChannel?.id) {
        return;
      }
      return (0, _desktopNotifications.onNotification)(data, this.siteSettings, this.currentUser, this.appEvents);
    }
    static #_5 = (() => dt7948.n(this.prototype, "onMessage", [_decorators.bind]))();
    get #shouldRun() {
      return this.site.desktopView && this.chat.userCanChat && !(0, _environment.isTesting)();
    }
  }
  _exports.default = ChatNotificationManager;
});