import { template as template_6a6714a7b0f1480db2a548ea0a2124ed } from "@ember/template-compiler";
const SidebarSectionMessage = template_6a6714a7b0f1480db2a548ea0a2124ed(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
